@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url(../fonts/Lato-Italic.ttf) format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Lato-Black.ttf) format("truetype");
}
